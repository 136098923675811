import { subDays } from "date-fns";

const today = new Date();
today.setHours(0, 0, 0, 0);

export class GenerateDate {
  static getDates()  {
    const startDate = subDays(today, 30);
    const endDate = subDays(today, 1);
    return { 
      startDate,
      endDate
    }
  }

  static getYesterday() {
    const yesterday = subDays(today, 1);
    const startDate = subDays(yesterday, 1);
    const endDate = yesterday
    return { 
      startDate,
      endDate
    }
  }

  static getDateMinusDays(days: number) {
    const startDate = subDays(today, days);
    const endDate = today
    return { 
      startDate,
      endDate
    }
  }
}