<div class="image-verification-map-container">
  <div class="image-verification-map-content">    
    <button type="button" class="btn-close-default" (click)="closeMap()">
      <span class="fa fa-times-circle"></span>
    </button>
    <agm-map 
      #agmMap
      style="width: 100%; height: 97%;" 
      [latitude]="centerMap.lat"
      [longitude]="centerMap.lng"
      [zoom]="zoomMap"
      [disableDefaultUI]="false"
      [zoomControl]="mapConfig.zoomControl"
      [streetViewControl]="mapConfig.streetViewControl"
      [fullscreenControl]="mapConfig.fullscreenControl"
    >      
      <agm-marker
        [latitude]="_locations.lat"
        [longitude]="_locations.lng"
        [iconUrl]="mapIcons.iconInventory"
        [zIndex]="2"
      >      
        <agm-info-window>
          <div class="container-carousel">                
            <ngx-slick-carousel
              class="slick-slider slick-dotted"
              #slickModal="slick-carousel" 
              [config]="slideConfig"              
            >                  
              <div ngxSlickItem *ngFor="let photo of _imagesUrls; let i = index" class="img-container">
                <img 
                  [src]="photo"
                  alt="Image {{ i }}"                   
                  class="carousel-image"
                >
              </div>                  
            </ngx-slick-carousel>                 
          </div> 
        </agm-info-window>
      </agm-marker>

      <agm-marker
        *ngFor="let photo of _locations.photos; let i = index"
        [latitude]="photo.lat"
        [longitude]="photo.lng"
        [iconUrl]="mapIcons.iconPhotoInventory"
        [zIndex]="1"
      > 
        <agm-info-window>        
          <div class="image-verification-map-photo">
            <div class="img-popup">
              <img
                [src]="_imagesUrls[photo.imageIndex - 1]"
                (click)="openZoomImageModal(_imagesUrls[photo.imageIndex - 1])"
                alt="incident"
                class="image-popup"
              />
            </div>                        
          </div>
        </agm-info-window>
      </agm-marker>
    </agm-map>
  </div>
</div>
