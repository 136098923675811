<div class="modal-header">
  <h5 class="modal-title">Gerar relatório de técnico</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('cancel')"></button>
</div>

<div class="modal-body">

  <form [formGroup]="reportForm">

    <div class="mb-3">
      <label for="technicianId" class="form-label">Técnico</label>
      <select id="technicianId" formControlName="technicianId" class="form-select">
        <option [value]="technicianId">{{ technicianId }}
        </option>
      </select>
    </div>
  
    <div class="mb-3">
      <label for="company" class="form-label">Empresa</label>
      <select id="company" formControlName="company" class="form-select">
        <option [value]="company">{{ company }}</option>
      </select>
    </div>

    <div class="mb-3">
      <label for="fileType" class="form-label">Tipo de arquivo</label>
      <select id="fileType" formControlName="fileType" class="form-select">
        <option checked value="PDF">PDF</option>
      </select>
    </div>

    <div class="mb-3 custom-multiselect">
      <label for="validations" class="form-label">Validações</label>
    
      <div id="validations" formArrayName="validations" class="form-group">
        <div class="checkbox-item" *ngFor="let option of legends; let i = index">
          <input
            type="checkbox"
            [formControlName]="i"
            [value]="option.value"
            id="checkbox-{{i}}">
          <label for="checkbox-{{i}}">{{ option.description }}</label>
        </div>
      </div>
    </div>    
  </form>

  <div *ngIf="isLoading" class="text-center mt-3">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Carregando...</span>
    </div>
    <p class="mt-2">Gerando, por favor aguarde...</p>
  </div>
</div>

<div class="modal-footer">
  <button
    class="btn btn-primary"
    [disabled]="reportForm.invalid"
    (click)="sendTechnicianReportEmail()"
  >
    Gerar relatório
  </button>
</div>
