import { da } from "date-fns/locale";
import { ITicket } from "src/app/interfaces";

export const toMap = (data: any): ITicket  => {
  return {
    id: data.id,
    incidentId: data.incidentId,
    guid: data.guid,
    ticketId: data.ticketId,
    taskIdIncident: data.taskIdincident,
    ticketSystemOrigin: data.ticketSystemOrigin,
    dtroute: data.dtroute,
    reportedDate: data.reportedDate,
    cause: data.cause,
    state: data.state,
    city: data.city,
    lat: data.lat,
    lng: data.lng,
    latStart: data.latStart,
    latEnd: data.latEnd,
    lngStart: data.lngStart,
    lngEnd: data.lngEnd,
    status: data.status,
    sla: data.sla,
    incidentType: data.incidentType,
    productOperational1: data.productOperational1,
    productOperational2: data.productOperational2,
    cause1: data.cause1,
    cause2: data.cause2,
    address: data.address,
    company: data.company,
    technicianId: data.technicianId,
    operational: data.operational,
    operational2: data.operational2,
    operational3: data.operational3,
    directors: data.directors,
    regional: data.regional,
    subcluster: data.subcluster,
    cluster: data.cluster,
    causeGroup:  data.causeGroup,
    quantity: data.quantity
  }
}
interface PredicateOption {
  ticket: ITicket;
  keyTicket: keyof ITicket;
  years: string[] | null;
  filters: string;
  isUpper: boolean;
}

export const isValidLatLng = (ticket: ITicket) => {
  return ticket.lat !== 0 && ticket.lng !== 0
};

export const predicateTicket = ({ ticket, keyTicket, years, filters, isUpper }: PredicateOption): boolean => {
  const ticketYear = new Date(ticket.reportedDate).getFullYear().toString();
  let treatResult: string = ticket[keyTicket].toString();
  if (isUpper) treatResult = treatResult.toUpperCase();
  const isInYear = !years || years.includes(ticketYear);
  const isInFilters = !filters || filters.includes(treatResult);
  return isInYear && isInFilters;
}
