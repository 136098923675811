type MappedType = 'displayName' | 'shortName';

type MappedKeyType = 'groups' | 'regionals' | 'validations';

const mappedValues: Record<MappedKeyType, Record<string, { displayName: string, shortName: string }>> = {
  groups: {
    'GRUPO BAHIA/SERGIPE': { displayName: 'Grupo Bahia/Sergipe', shortName: 'BA/SE' },
    'GRUPO CENTRO-OESTE': { displayName: 'Grupo Centro-Oeste', shortName: 'CO' },
    'GRUPO MINAS GERAIS': { displayName: 'Grupo Minas Gerais', shortName: 'MG' },
    'GRUPO NORDESTE': { displayName: 'Grupo Nordeste', shortName: 'NE' },
    'GRUPO NORTE': { displayName: 'Grupo Norte', shortName: 'NO' },
    'GRUPO RIO E ES': { displayName: 'Grupo Rio e ES', shortName: 'RJ/ES' },
    'GRUPO PR': { displayName: 'Grupo PR', shortName: 'PR' },
    'GRUPO RS': { displayName: 'Grupo RS', shortName: 'RS' },
    'GRUPO SC': { displayName: 'Grupo SC', shortName: 'SC' },
    'GRUPO SP CAPITAL': { displayName: 'Grupo SP Capital', shortName: 'SPC' },
    'GRUPO SP INTERIOR': { displayName: 'Grupo SP Interior', shortName: 'SPI' }
  },
  regionals: {
    'REGIONAL LESTE': { displayName: 'Regional Leste', shortName: 'RL' },
    'REGIONAL SUL': { displayName: 'Regional Sul', shortName: 'RS'},
    'REGIONAL SÃO PAULO': { displayName: 'Regional São Paulo', shortName: 'RSP' }
  },
  validations: {
    'GEOLOCALIZAÇÃO DIVERGENTE': { displayName: 'Geolocalização divergente', shortName: 'GPS' },
    'ARQUIVO COM TAMANHO MUITO PEQUENO': { displayName: 'Arquivo com tamanho muito pequeno', shortName: 'SIZE' },
    'DATA DIVERGENTE': { displayName: 'Data divergente', shortName: 'DATE' },
    'ARQUIVO COM DIMENSÕES MUITO PEQUENAS': { displayName: 'Arquivo com dimensões muito pequenas', shortName: 'DIMENSIONS' },
    'PIXELS BRANCOS/PRETOS': { displayName: 'Pixels brancos/pretos', shortName: 'PIXEL' },
    'ARQUIVOS DUPLICADOS': { displayName: 'Arquivos Duplicados', shortName: 'DUPLICATE' },
    'ARQUIVO SEM METADADOS': { displayName: 'Arquivo sem metadados', shortName: 'META' },
    'NITIDEZ IRREGULAR': { displayName: 'Nitidez Irregular', shortName: 'SHARPNESS' }
  }
};

const hasGroupName = (value: string) => {
  return mappedValues.groups[value.toUpperCase()] !== undefined;
}

const getMapped = ({ key, type, value }: { key: MappedKeyType, type: MappedType, value: string }) => {
  const response = mappedValues[key][value.toUpperCase()];
  return response ? response[type] : value; 
}

const processValues = ({ key, type, values }: { key: MappedKeyType, type: MappedType, values: string }) => {
  return values.split(',')
    .map(value => getMapped({ key, type, value }))
    .filter(value => value !== undefined)
    .join(',');
}

export {
  getMapped,
  processValues,
  hasGroupName
}