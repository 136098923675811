import { trigger, state, style, animate, transition } from '@angular/animations';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IColors, VandalismAnalytical, VandalismDetail } from 'src/app/interfaces';
import { CauseOption } from 'src/app/interfaces/enums/CauseOption';
import { AnalyticalService } from 'src/app/services/analytical.service';
import { FilterService, Namespace } from 'src/app/services/filter.service';
import { ThemeService } from 'src/app/services/theme.service';
import { initialStateActiveFilters } from 'src/app/utils/constants';
import { icons } from 'src/app/utils/icons';
import { mapCauseOption } from 'src/app/utils/mappers/causeOptions';

@Component({
  selector: 'app-detalhamento',
  templateUrl: './detalhamento.component.html',
  animations: [
    trigger('slideInOut', [
      state('in', style({
        'max-height': '500px',
        opacity: '1',
        visibility: 'visible'
      })),
      state('out', style({
        'max-height': '0',
        opacity: '0',
        visibility: 'hidden'
      })),
      transition('in => out', [animate('800ms ease-in-out')]),
      transition('out => in', [animate('800ms ease-in-out')])
    ])
]})
export class DetalhamentoComponent {

  private static FULLSCREEN_URL: string = '/vandalismo/mapa'

  NAMESPACE: Namespace = 'detail';

  colors: IColors;
  loading: boolean = true;
  error: boolean = false;
  isDivVisible = false;
  
  data: BehaviorSubject<VandalismDetail.Output> = new BehaviorSubject(new VandalismDetail.Output());
  undoDisabled: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isPeadsActivated: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isAccumulatedPeadsActivated: BehaviorSubject<boolean> = new BehaviorSubject(true);
  activeFilters: VandalismDetail.InputParams = VandalismDetail.initialStateActiveFilters;
  historyActiveFilters: Array<VandalismDetail.InputParams> = [];
 
  requestWrapperConfig = {
    cardStyle: 'widget-chart widget-chart2 text-start mb-1 card-btm-border card-shadow-primary border-primary card',
    chartSmallStyle: 'card-chart chart-small-geral',
    chartFullStyle: 'card-chart chart-full'
  }

  icons=icons

  filters = {
    year: 'ano',
    family: 'família',
    net: 'rede',
    month: 'mês',
    day: 'dia',
    regional: 'regional',
    group: 'grupo'
  }

  constructor(
    private filterService: FilterService,
    private themeService: ThemeService,
    private analyticalService: AnalyticalService,
    private router: Router
    ) {
      this.colors = this.themeService.getColors();
      
      const filteredTickets = this.analyticalService.filterTickets(this.activeFilters);
      const { dropdownList, selectedList } = this.analyticalService.getFilters(this.activeFilters, filteredTickets);

      this.activeFilters = VandalismDetail.initialStateActiveFilters;

      this.data.next(new VandalismDetail.Output({
        activeFilters: this.activeFilters,
        dropdownList: dropdownList,
        selectedList: selectedList,
        tickets: filteredTickets
      }));

      this.filterService.setDefaultFilters<VandalismDetail.InputParams>({
        namespace: this.NAMESPACE,
        defaultFilters: this.activeFilters,
      });

      this.filterService.getFiltersObservable<VandalismDetail.InputParams>({
        namespace: this.NAMESPACE
      })
        .pipe(debounceTime(100))
        .subscribe((af) => this.updateData(af));

      this.analyticalService.isLoaded.asObservable().subscribe((isLoaded) => {
        this.loading = !isLoaded;
        this.error = !isLoaded
        this.updateData(this.activeFilters);
      });
  }
  
  ngOnDestroy(): void {
    this.filterService.clearNamespace({ namespace: this.NAMESPACE });
    this.data.unsubscribe();
  }

  openHeaderFilter() {
    this.isDivVisible = !this.isDivVisible;    
  }

  // TODO
  updateData(activeFilters: VandalismDetail.InputParams) {
    this.activeFilters = activeFilters;
    const isAccumulatedPeadsActivated = this.isAccumulatedPeadsActivated.value;
    const filteredTickets = this.analyticalService.filterTickets(this.activeFilters);
    const { dropdownList, selectedList } = this.analyticalService.getFilters(this.activeFilters, filteredTickets);  
    this.data.next(new VandalismDetail.Output({
      activeFilters: this.activeFilters,
      dropdownList: dropdownList,
      selectedList: selectedList,
      tickets: filteredTickets
    }));
  }

  openFullscreen() {
    this.router.navigate([DetalhamentoComponent.FULLSCREEN_URL])
  }

  // TODO: Classe abstrata de filtragem que contém essa lógica.
  onUndo(): void {
    const prevValue = this.historyActiveFilters.pop();
    if (prevValue !== undefined) {
      this.filterService.updateMultipleFilters<VandalismAnalytical.InputParams>({
        namespace: this.NAMESPACE,
        newFilters: prevValue
      });
      const isLastElement: boolean = this.historyActiveFilters.length === 0;
      this.undoDisabled.next(isLastElement);
      this.toggleActivatePeads(prevValue.causeGroup.includes('PEAD'));
    }
  }

  // TODO: Classe abstrata de filtragem que contém essa lógica.  
  onReset() {
    this.toggleActivatePeads(false);
    this.filterService.updateMultipleFilters({
      namespace: this.NAMESPACE,
      newFilters: initialStateActiveFilters,
    });
  }

  onVandalism() {
    this.onChange('causeGroup:VANDALISMO');
    this.onActivatePeadsChange(false);
    this.onAccumulatedPeadsChange(false);
  }

  onActivatePeadsChange(isActivated: boolean) {
    this.toggleActivatePeads(isActivated);
  }

  onAccumulatedPeadsChange(isActivated: boolean) {
    this.toggleAccumulatedPeads(isActivated);
  }

  toggleAccumulatedPeads(shouldActivate: boolean) {
    const isActive = shouldActivate !== undefined ? shouldActivate : !this.isAccumulatedPeadsActivated.value;
    this.isAccumulatedPeadsActivated.next(isActive && this.isPeadsActivated.value);
    this.filterService.actionChange({ namespace: this.NAMESPACE });
  }

  // TODO: Muita lógica junta e pode ser refatorada para outras functions
  toggleActivatePeads(shouldActivate: boolean) {
    const isActive = shouldActivate !== undefined ? shouldActivate : !this.isPeadsActivated.value;
    const activateCausesWithoutPead = this.activeFilters.causeGroup
      .split(',')
      .filter((cause) => cause !== 'PEAD')
      .join(',');

    if (!isActive && this.isAccumulatedPeadsActivated.value) {
      this.toggleAccumulatedPeads(false);
    }

    this.isPeadsActivated.next(isActive);
    this.onChange(`causeGroup:${activateCausesWithoutPead}${isActive ? ',PEAD' : ''}`);
    this.filterService.actionChange({ namespace: this.NAMESPACE });
  }

  onChange(event: string) {
    const [key, values] = event.split(':');

    let newActiveFilters = this.activeFilters;
    let newValues = values;

    if (key === "causeGroup") {
      // TODO: Método para fazer isso.     
      const notNull = (value) => value !== null;
      const causes = values.split(',').map(mapCauseOption).filter(notNull);
            
      if (!values.includes('PEAD') && !causes.includes(CauseOption.PEAD) && this.isPeadsActivated.value) {
        const valuesArr = values !== '' ? values.split(',') : [];
        causes.push(CauseOption.PEAD);
        valuesArr.push('PEAD');
        newValues = valuesArr.length < 1 ? valuesArr.join('') : valuesArr.join(',');
      };

      // this.analyticalService.setFileName(causes);
    };

    this.historyActiveFilters.push(newActiveFilters);
    const isLastElement: boolean = this.historyActiveFilters.length === 0;
    this.undoDisabled.next(isLastElement);

    this.filterService.updateFilters<VandalismAnalytical.InputParams>({
      namespace: this.NAMESPACE,
      key,
      values: newValues
    });
  }
}

