import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChartOptions } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ImageAnalytical, initialDataImageAnalytical, initialImageAnalyticalData } from "src/app/interfaces/dtos/ImageAnalytical";
import { TicketPopUpComponent } from "src/app/maps/ticket-pop-up/ticket-pop-up.component";
import { FilterService, Namespace } from "src/app/services/filter.service";
import { ImageVerificationService } from "src/app/services/image-verification.service";
import { families, months } from "src/app/utils/constants";
import { Formatter } from "src/app/utils/formatter.util";
import { GenerateDate } from "src/app/utils/generateDate.util";
import { toMap } from "src/app/utils/interfaces/ticket";
import { hasGroupName, getMapped, processValues } from "../utils/mapped-values";
import { icons } from "src/app/utils/icons";
import { ReportImagesModalComponent } from "./components/report-images-modal/report-images-modal.component";
import { ThrowStmt } from "@angular/compiler";

@Component({
  selector: 'app-analitico-images',
  templateUrl: './analitico-images.component.html',
  styleUrls: ['./analitico-images.component.scss']
})
export class AnaliticoImagesComponent implements OnInit {

  private NAMESPACE: Namespace = 'imageAnalytical';

  @Input() width = 50;
  @Output() filterClick = new EventEmitter<string>();
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  data: BehaviorSubject<ImageAnalytical.Response> = new BehaviorSubject(initialDataImageAnalytical);
  activeFilters: ImageAnalytical.Params;
  view: BehaviorSubject<ImageAnalytical.ViewGraphContent> = new BehaviorSubject('analyticalTicketsByGroup');
  filters: ImageAnalytical.Filters = initialImageAnalyticalData;
  selectedDirectors: BehaviorSubject<string[]> = new BehaviorSubject([]);
  ticketsTable: BehaviorSubject<Array<Array<string>>> = new BehaviorSubject([]);
  showTable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedTicket: BehaviorSubject<number | null> = new BehaviorSubject(null);
  families=families;
  icons=icons;
  barWidthProportion = '100%';
  barChartData = { datasets: [], labels: [] };
  colors = [ '#808080', '#FF0000', '#8B0000', '#000000'];
  loading: boolean = true;
  error: boolean = false;
  isFoundContent: boolean = false;

  requestWrapperConfig = {
    chart: 'widget-chart widget-chart2 text-start mb-1 card-btm-border card-shadow-primary border-primary height-chart-image-verification',
  }

  constructor(
    private filterService: FilterService,
    private imageVerificationService: ImageVerificationService,
    private modalService: NgbModal
  ) {
    const { startDate, endDate } = GenerateDate.getDates();

    const defaultActiveFilters: ImageAnalytical.Params = {
      startDate: Formatter.formatDataPickerDate(startDate),
      endDate: Formatter.formatDataPickerDate(endDate),
      causes: "VANDALISMO",
      families: "",
      regionals: "",
      directors: ""
    }

    this.filterService.setDefaultFilters<ImageAnalytical.Params>({
      namespace: this.NAMESPACE,
      defaultFilters: defaultActiveFilters
    });

    this.filterService.getFiltersObservable<ImageAnalytical.Params>({
      namespace: this.NAMESPACE
    })
      .pipe(debounceTime(500))
      .subscribe((af) => {
        this.fetchData(af)
      });
  }

  fetchData(activeFilters: ImageAnalytical.Params) {
    this.loading = true;

    return this.imageVerificationService.getAnalyticalImage(activeFilters)
      .subscribe((response) => {
        this.loading = false;
        this.error = false;
        this.isFoundContent = response[this.view.value].datasets && response[this.view.value].datasets.length > 0;
        const output = new ImageAnalytical.Output(response);
        this.data.next(output.data);
        let directors = activeFilters.directors
          .split(',').filter((value) => value !== '')
          .map((value) => value.toUpperCase());       
        this.selectedDirectors.next(directors);
        this.activeFilters = activeFilters;
        this.createBarChart();
      }, (error) => {
        this.loading = false;
        this.error = true;
        this.isFoundContent = false;
      });
  }

  options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    onClick: (event, activeElements: Array<any>) => {
      if (activeElements.length > 0) {
        let firstElement = activeElements[0];
        let elementIndex = firstElement._index;
        let label = this.barChartData.labels[elementIndex];
        let labelUpper = label.toUpperCase();
        let value = labelUpper;

        if (hasGroupName(value)) {
          this.onChange(`directors:${value}`);

          if (this.isCurrentView('analyticalTicketsByGroup')) {
            this.toggleView('analyticalPhotosByValidation');
          }
        } else {
          if (this.isCurrentView('analyticalPhotosByValidation'))  {
            this.onClickTable(elementIndex);
            this.showTable.next(!this.showTable.value && elementIndex !== this.selectedTicket)
            this.selectedTicket.next(elementIndex)
          }

          if (this.isCurrentView('analyticalPhotosByTechnical'))  {
            this.onClickTable(elementIndex);
            this.showTable.next(!this.showTable.value && elementIndex !== this.selectedTicket)
            this.selectedTicket.next(elementIndex)
          }
        }
    }},    
    layout: {
      padding: {
        top: 15,
        left: -5,
        right: -5,
        bottom: 0
      }
    },
    scales: { 
      xAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          display: true,
          beginAtZero: true,
          callback: (value: string) => {            
            if (hasGroupName(value)) {
              return getMapped({ key: 'groups', type: 'shortName', value });
            }

            return value;
          }
        }
      }],
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true
          },
          gridLines: {
            display: false
          },
        }
     ] 
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const activeData = this.data.value[this.view.value];
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const value = dataset.data[tooltipItem.index];
          const percentage = activeData.datasets[tooltipItem.index][2];
          const legends = activeData.legends;  
          if (tooltipItem.datasetIndex % 2 === 0) {
            return `${legends[0]}: ${value}`;
          } else {
            return `${legends[1]} (${legends[2]}): ${value} (${percentage}%)`;
          }
        }
      }
    },
    plugins: {
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'end',
        color: 'black',
        offset: -4,
        font: { weight: 'bold', size: 11 },
      }
    }
  };

  ngOnInit(): void {}

  openTechnitionReportModal() {

    if (this.isCurrentView("analyticalPhotosByTechnical") && this.showTable) {
      const modalRef = this.modalService.open(ReportImagesModalComponent);
      const index = this.selectedTicket.value
      const data = this.ticketsTable.value[index];
      modalRef.componentInstance.activeFilters = this.activeFilters;
      modalRef.componentInstance.technicianId = data[3];
      modalRef.componentInstance.company = data[4];
    }
  }

  isCurrentView(view: ImageAnalytical.ViewGraphContent): boolean {
    return this.view.value === view;
  }

  onClickTable(index: number) {
    this.generateTableValue(index);
  }
  
  generateTableValue(index: number) {
    const tickets = this.data.value[this.view.value].tickets[index];
    this.ticketsTable.next(tickets);
  }

  isAnalyticalPhotosByTechnical(): boolean {
    return this.view.value === 'analyticalPhotosByTechnical';
  }

  createBarChart() {
    const { datasets: dataset, labels } = this.data.value[this.view.value];
    const maxColumns = dataset ? Math.max(...dataset.map(d => d.length)) : 0;
    const barQuantity = dataset.reduce((a, c) => a + c.length, 0) 
    const datasets = [];

    let treatedLabels = labels;

    if (this.isCurrentView('analyticalTicketsByYear')) {
      treatedLabels = labels.map((label) => {
        if (label) {
          const [month, year] = label.split('/');
          const monthName = months[Number(month) - 1];
          return `${monthName ?? month}/${year}`;
        }
      });
    }

    this.updateBarProportion(barQuantity);

    const chartType = (dataIndex: number): 'line' | 'bar' => {
      if (maxColumns > 2 && dataIndex === (maxColumns - 1)) return 'line';
      return 'bar';
    }

    for (let i = 0; i < maxColumns; i++) {
      const data = (dataset ?? []).map(d => d[i] ?? 0)
      const colorsArr = Array(20).fill(this.colors[i % this.colors.length])
      
      let params = {     
        data: data,
        type: chartType(i),
        order: i + 1,
        fill: colorsArr,
        backgroundColor: colorsArr,
        hoverBackgroundColor: colorsArr,
        borderColor: this.colors[i % this.colors.length]
      }

      if (chartType(i) === 'line') {
        const lastDatasetAdded = datasets.pop();

        datasets.push({
          ...lastDatasetAdded,
          datalabels: {
            align: 'end',
            anchor: 'end',
            font: {
              weight: 'bold',
              size: 10
            },
            formatter: (value, context) => {
              const index = context.dataIndex;
              if (value !== 0 && data[index] !== 0) {
                return `${value} (${data[index]}%)`
              }
              if (value !== 0 && data[index] === 0) {
                return `${value}`
              }
              return value;
            }
          },
        });
      } else {
        datasets.push(params);
      }
    }

    this.barChartData = {
      datasets,
      labels: treatedLabels
    }
  };

  toggleView(value: ImageAnalytical.ViewGraphContent) {
    this.view.next(value);
    this.createBarChart();
  };

  getRangeDate() {
    const { startDate, endDate } = GenerateDate.getYesterday();
    return { startDate, endDate };
  }

  openTicketPopUp(ticketId: number) {
    this.imageVerificationService
      .getTicketsById(ticketId.toString())
      .subscribe((data) => {
        let ticket = toMap(data[0]);       
        const modalRef = this.modalService.open(TicketPopUpComponent, { size: 'lg' });
        modalRef.componentInstance.modalData = ticket;
      });
  }

  viewChange(values: string) {
    const mappedViews: Record<string, ImageAnalytical.ViewGraphContent> = {};
    const condition = this.selectedDirectors.value.length > 0;

    if (condition) {
      mappedViews['Tickets'] = 'analyticalTicketsByGroup';
      mappedViews['Técnicos'] = 'analyticalPhotosByTechnical';
      mappedViews['Month'] = 'analyticalTicketsByYear';
    } else {
      mappedViews['Tickets'] = 'analyticalPhotosByValidation';
      mappedViews['Técnicos'] = 'analyticalPhotosByTechnical';
    }

    if (mappedViews[values]) {
      this.toggleView(mappedViews[values]);
    }
  }

  setView(newView: ImageAnalytical.ViewGraphContent) {
    this.view.next(newView);
    this.showTable.next(false);
    this.selectedTicket.next(null);
    this.createBarChart();
  }

  updateChartSize() {
    if (this.chart && this.chart.chart) {
      setTimeout(() => {
        this.chart.chart.resize();
        this.chart.chart.update();
      }, 300);
    }
  }

  updateBarProportion(barQuantity: number) {
    this.barWidthProportion = `100%`
    this.updateChartSize();
  }

  onChange(event: string) {
    const [key, values] = event.split(':');

    let newValues = values;

    if (key === 'view') {
      this.viewChange(values); 
    }

    if (key === 'directors') {
      newValues = processValues({ key: 'groups', type: 'displayName', values: newValues });
    }
    
    if (key === 'regionals') {
      newValues = processValues({ key: 'regionals', type: 'displayName', values: newValues });
    }

    this.filterService.updateFilters<ImageAnalytical.Params>({
      namespace: this.NAMESPACE,
      key,
      values: newValues,
    });
  }
}