import { subDays } from "date-fns";
import { ChartInput, VandalismAnalytical } from "src/app/interfaces";
import { AnalyticalLegend } from "src/app/interfaces/enums/AnalyticalLegend";
import { LegendItem, LegendType } from "src/app/interfaces/models/ILegendItem";

const currentDate = subDays(new Date(), 1);
const LIMIT = 4;

export const lastYears = Array
  .from({ length: LIMIT }, (_, i) => (currentDate.getFullYear() - i)
  .toString()); // ['2021', '2022', '2023', '2024']

export const getDateUtils = () => {
  const currentMonth = currentDate.getMonth() + 1
  const currentYear = currentDate.getFullYear()
  const lastYear = currentDate.getFullYear() - 1

  return {
    currentMonth: currentMonth.toString(),
    currentYear: currentYear.toString(),
    lastYear: lastYear.toString(),
  }
}

export const getDefaultFilters = (input: Partial<VandalismAnalytical.InputParams>) => {
  const defaultActiveFilters: VandalismAnalytical.InputParams = {
    day: '',
    year: '',
    month: '',
    family: '',
    net: '',
    regional: '',
    state: '',
    group: '',
    cluster: '',
    subcluster: '',
    city: '',
    causeGroup: '',
    isPead: true,
    isAccumulated: false
  };

  return {
    ...defaultActiveFilters,
    ...input
  };
}

export const analyticalDefaultLegend: LegendItem<AnalyticalLegend>[] = [
  { name: AnalyticalLegend.RESIDENCIAL, color: 'red', isVisible: true, type: LegendType.CIRCLE },
  { name: AnalyticalLegend.EMPRESARIAL, color: 'black', isVisible: true, type: LegendType.CIRCLE },
  { name: AnalyticalLegend.PEAD, color: 'green', isVisible: true, type: LegendType.TRIANGLE }
];

export const initialValueChartInput: ChartInput = {
  dataset: [],
  labels: []
};

export const initialValueVolumeChartInput = {
  dataset: [{ label: [], data: [] }],
  labels: []
};

