import { Component, OnInit } from '@angular/core';
import { unicode } from 'src/app/utils/unicode';
import { IVerification } from './components/verification-span/verification-span.component';
import { FilterService, Namespace } from 'src/app/services/filter.service';
import { BehaviorSubject } from 'rxjs';
import { ImageVerificationNsp, initialDataImageVerification, VerificationOption } from 'src/app/interfaces/dtos/ImageValidation';
import { GenerateDate } from 'src/app/utils/generateDate.util';
import { icons } from 'src/app/utils/icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TicketPopUpComponent } from 'src/app/maps/ticket-pop-up/ticket-pop-up.component';
import { toMap } from 'src/app/utils/interfaces/ticket';
import { ImageVerificationService } from 'src/app/services/image-verification.service';
import { Formatter } from 'src/app/utils/formatter.util';
import { debounceTime } from 'rxjs/operators';
import { getMapped, processValues } from '../utils/mapped-values';

export const verificationOptionsMap: Record<string, VerificationOption> = {
  'SUCCESS': VerificationOption.SUCCESS,
  'FAILED':  VerificationOption.FAILED,
  'GPS':  VerificationOption.GPS,
  'SIZE': VerificationOption.SIZE,
  'DATE':  VerificationOption.DATE,
  'DIMENTIONS':  VerificationOption.DIMENTIONS,
  'PIXEL':  VerificationOption.PIXEL,
  'META': VerificationOption.META,
  'DUPLICATE': VerificationOption.DUPLICATE,
}

export interface Legendverification {
  description: string;
  value: string;
}

export interface ImageVerification {
  ticketId: number;
  imageValidation: boolean;
  quantity: number;
  validations: string[][];
}

@Component({
  selector: 'app-verificacao-images',
  templateUrl: './verificacao-images.component.html',
  styleUrls: ['./verificacao-images.component.scss']
})
export class VerificacaoImagesComponent implements OnInit {

  NAMESPACE: Namespace = 'imageVerification';
  data: BehaviorSubject<ImageVerificationNsp.Response> = new BehaviorSubject(initialDataImageVerification);
  filters: ImageVerificationNsp.Filters = initialDataImageVerification.filters.dropdown;
  selectedValidations: BehaviorSubject<String[]> = new BehaviorSubject([]);
  loading: boolean = true;
  error: boolean = false;
  icons = icons;

  requestWrapperConfig = {
    cardStyle: 'widget-chart widget-chart2 text-start mb-1 card-btm-border card-shadow-primary border-primary',
    table: 'widget-chart widget-chart2 text-start mb-1 card-btm-border card-shadow-primary border-primary height-table-image-verification',
    chartSmallStyle: 'card-chart chart-geral'
  }

  constructor(
    private filterService: FilterService,
    private modalService: NgbModal,
    private imageVerificationService: ImageVerificationService
  ) {
    const { startDate, endDate } = GenerateDate.getDates();

    const defaultActiveFilters: ImageVerificationNsp.Params = {
      startDate: Formatter.formatDataPickerDate(startDate),
      endDate: Formatter.formatDataPickerDate(endDate),
      validations: 'ALL',
      causes: 'VANDALISMO',
      directors: '',
      regionals: ''
    }

    this.filterService.setDefaultFilters<ImageVerificationNsp.Params>({
      namespace: this.NAMESPACE,
      defaultFilters: defaultActiveFilters
    });

    this.filterService.getFiltersObservable<ImageVerificationNsp.Params>({
      namespace: this.NAMESPACE
    })
      .pipe(debounceTime(1000))
      .subscribe((activeFilters) => {
        this.fetchData(activeFilters)
      });
  }

  ngOnInit(): void {}

  fetchData(activeFilters: ImageVerificationNsp.Params) {
    this.loading = true;

    const validations = activeFilters
      .validations
      .split(',')
      .map((v: string) => {
        if (v !== '') {
          return getMapped({ key: 'validations', type: 'shortName', value: v.toUpperCase() })
        } else {
          return 'ALL'
        }
      })
      .join(',');

    return this.imageVerificationService
      .getImages({ ...activeFilters, validations })
      .subscribe((response) => {
        this.loading = false;
        this.error = false;
        const output = new ImageVerificationNsp.Output(response).data;
        this.data.next(output);
        
        const activeValidations = activeFilters
          .validations
          .split(',')
          .map((v: string) => getMapped({ key: 'validations', type: 'displayName', value: v.toUpperCase() }))
          .filter((value) => value !== 'ALL' && value !== '');

        this.selectedValidations.next(activeValidations);
      }, (error) => {
        this.loading = false;
        this.error = true;
      });
  }

  completeList(filter: string, dropdownList: string[]) {
    if (filter.length === 0) {
      return dropdownList;
    }
    return filter.split(',');
  }

  mappedValidation(verification: string): IVerification {
    return { type: verificationOptionsMap[verification], symbol: unicode.CIRCLE };
  }

  isActive(description: string): boolean {
    return this.selectedValidations.value.includes(description);
  }

  getTextColor(cause: boolean): string {
    return cause ? 'isSuccess' : 'isFailure';
  }

  mappedValidationCause(cause: boolean): IVerification  {
    const condition = cause;
    const type = this.getTextColor(cause);
    const symbol = condition ? unicode.OK : unicode.FAILURE;
    return { type, symbol }
  }

  openTicketPopUp(ticketId: number) {
    this.imageVerificationService
      .getTicketsById(ticketId.toString())
      .subscribe((data) => {
        let ticket = toMap(data[0]);       
        const modalRef = this.modalService.open(TicketPopUpComponent, { size: 'lg' });
        modalRef.componentInstance.modalData = ticket;
      });
  }

  getLegendRows(legends: Legendverification[]): any[][] {
    let numRows = 4;
    let perRow = Math.ceil(legends.length / numRows);
    let rows = [];
    for (let i = 0; i < legends.length; i += perRow) {
      rows.push(legends.slice(i, i + perRow));
    }
    return rows;
  }

  getRangeDate() {
    const { startDate, endDate } = GenerateDate.getYesterday();
    return { startDate, endDate };
  }

  setValidations(value: string) {
    const selectedValidations = [...this.selectedValidations.value].filter((e) => e !== undefined);
    const arrWithoutValue = selectedValidations.filter((v) => v !== value);
    const arrWithValue = [...selectedValidations, value];
    if (!this.isActive(value)) {
      this.onChange(`validations:${arrWithValue.join(',')}`) 
    } else {
      this.onChange(`validations:${arrWithoutValue.join(',')}`)
      this.selectedValidations.next(arrWithoutValue);
    }
  }

  onChange(event: string) {
    const [key, values] = event.split(':');
    let newValues = values;

    if (key === 'directors') {
      newValues = processValues({ key: 'groups', type: 'displayName', values: newValues });
    }

    if (key === 'validations') {
      newValues = processValues({ key: 'validations', type: 'displayName', values: newValues });
    }

    this.filterService.updateFilters<ImageVerificationNsp.Params>({
      namespace: this.NAMESPACE,
      key,
      values: newValues
    });
  }
}
