import { AgmMap } from '@agm/core';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { mapConfig } from '../config/map-config';
import { VandalismTicketPopup } from 'src/app/interfaces';
import { inventoryIcons } from 'src/app/utils/map-icons';

@Component({
  selector: 'app-image-verification-map',
  templateUrl: './image-verification-map.component.html',
  styles: []
})
export class ImageVerificationMapComponent implements OnInit {

  @Input() set locations(value: VandalismTicketPopup.Location) {
    if (value) {
      this._locations = value;
      this.initializeMapMarkers();
    }
  }
  @Input() set imagesUrls(value){
    if(value)
    {
      this._imagesUrls = value;
      this.loadImages();
    }
  }
  @Output() mapClosed = new EventEmitter<void>();
  @ViewChild(AgmMap) agmMap: AgmMap;

  _locations: VandalismTicketPopup.Location;
  _imagesUrls: string[] = [];
  centerMap: { lat: number, lng: number} = {lat:-23.5505199,lng: -46.6333094};
  zoomMap = 8;
  mapIcons = inventoryIcons;
  map: google.maps.Map;
  mapConfig=mapConfig; 
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000
  };   
  
  constructor() { }
  
  ngOnInit(): void { }
  
  initializeMapMarkers() {    
    this.centerMap = {
      lat: this._locations.lat,
      lng: this._locations.lng
    };
    this.zoomMap = 20;   
    console.log(this._locations);
  }

  loadImages(){
    console.log(this._imagesUrls);
  }

  closeMap() {
    this.mapClosed.emit();
  }
}
