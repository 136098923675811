import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { addDays, subDays } from 'date-fns';
import { ThemeService } from './../../services/theme.service';
import { defineLocale, ptBrLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerDirective, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Formatter } from 'src/app/utils/formatter.util';
import { IColors } from 'src/app/interfaces';

@Component({
  selector: 'app-data-picker-default',
  templateUrl: './data-picker-default.component.html',
  styleUrls: []
})
export class DataPickerDefaultComponent implements OnInit {

  @ViewChild('startDate') startDatePicker: BsDatepickerDirective;
  @ViewChild('endDate') endDatePicker: BsDatepickerDirective;

  @Input() dates: { startDate: Date, endDate: Date };
  @Input() maxDate: Date;
  @Output() event = new EventEmitter<string>();

  startDateValue: Date;
  endDateValue: Date;

  bsConfig = {
    containerClass: 'theme-blue',
    showWeekNumbers: false,
    dateInputFormat: 'DD/MM/YYYY',
    adaptivePosition: true,
  };

  ngOnInit(): void {
    this.startDateValue = this.dates.startDate;
    this.endDateValue = this.dates.endDate;
  }

  constructor(
    private _localeService: BsLocaleService,
    private themeService: ThemeService
  ) {
    defineLocale('pt-br', ptBrLocale);
    this._localeService.use('pt-br');
  }

  getStartDate() {
    return this.startDatePicker?._bsValue;
  }

  getEndDate() {
    return this.endDatePicker?._bsValue;
  }

  getColors(): IColors {
    return this.themeService.getColors();
  }

  onChange(key: string) {
    if (this.getStartDate() > this.getEndDate()) {
      this.endDateValue = addDays(this.getStartDate(), 1);  
    }
    const startDate = Formatter.formatDataPickerDate(this.startDateValue);
    const endDate = Formatter.formatDataPickerDate(this.endDateValue);

    this.event.emit(`${key}:${key === 'startDate' ? startDate : endDate}`)
  }
}
