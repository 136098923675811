import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageAnalytical } from "src/app/interfaces/dtos/ImageAnalytical";
import { legends } from "src/app/interfaces/dtos/ImageValidation";
import { mountRequestResult, RequestResult } from "src/app/interfaces/RequestContext";
import { AuthService } from "src/app/services/auth.service";
import { PublisherService } from "src/app/services/publisher.service";

@Component({
  selector: 'app-export-users-modal',
  templateUrl: './report-images-modal.component.html',
  styleUrls: ['./report-images-modal.component.scss']
})
export class ReportImagesModalComponent implements OnInit {

  reportForm: FormGroup;
  activeFilters: ImageAnalytical.Params;
  legends=legends.filter(({value}) => !['SUCCESS', 'FAILED'].includes(value))

  company: string;
  technicianId: string;

  isLoading = false;

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private publisherService: PublisherService,
    private snackBar: MatSnackBar,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.reportForm = this.fb.group({
      technicianId: [this.technicianId, Validators.required],
      company: [this.company, Validators.required],
      fileType: ['PDF', Validators.required],
      validations: this.fb.array(this.legends.map(() => this.fb.control(true)), Validators.required)
    });
  }

  openPopUpResponse(requestResult: RequestResult) {
    const closeButtonMessage = 'Fechar';
    const snackBarSettings: MatSnackBarConfig = {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [requestResult.styleClass]
    }
    this.snackBar.open(requestResult.message, closeButtonMessage, snackBarSettings);
  }

  get validations(): FormArray {
    return this.reportForm.get('validations') as FormArray;
  }

  getSelectedValidations() {
    const selectedValues = this.reportForm.value.validations
      .map((checked, index) => checked ? this.legends[index].value : null)
      .filter(value => value !== null)
      .join(",");

    if (selectedValues.size === 0) {
      return legends.join(',');
    }

    return selectedValues
  }

  sendTechnicianReportEmail() {
    this.isLoading = true;

    if (this.reportForm.valid) {
      const { email, fullName } = this.authService.getCurrentUser();
      const { startDate, endDate, regionals, directors, families } = this.activeFilters;

      const payload = {   
        email: email,
        fullname: fullName,
        company: this.company, 
        technicianId: this.technicianId, 
        startDate, 
        endDate, 
        validations: this.getSelectedValidations(), 
        regionals, 
        directors, 
        families
      }

      this.publisherService
        .sendTechnicianReport(payload).subscribe(
          (response) => {
            this.isLoading = false;
            const requestResult: RequestResult = mountRequestResult({
              request: { ...response, status: 200 },
              message: response.message,
              expectedStatus: 200
            });
            this.openPopUpResponse(requestResult);
          },
          (error) => {
            this.isLoading = false;
            const requestResult: RequestResult = mountRequestResult({
              request: { ...error },
              message: error.error.message,
              expectedStatus: 200
            });
            this.openPopUpResponse(requestResult);
          })
        ;
    }
  }
}
