import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export type FileType = 'XLSX' | 'PDF';

export interface ImportResponse {
  imported: number;
  errors: number;
  errorMessages: string[];
  existingDatabaseEmails: string[];
  databaseDuplicates: number;
  tableDuplicatedEmails: string[];
  tableDuplicates: number;
  importDate: string;
}

@Injectable({
  providedIn: 'root'
})
export class PublisherService {

  private readonly baseUrl: string;
  private readonly publisherUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
    this.publisherUrl = environment.publisherUrl;
  }

  importDocument(input: { file: File, companyCode: number }): Observable<ImportResponse> {
    const formData: FormData = new FormData();
    const headers = new HttpHeaders();
    formData.append('file', input.file, input.file.name);
    return this.http.post<ImportResponse>(`${this.publisherUrl}/publisher/import-users/${input.companyCode}`, formData, { headers });
  }

  exportDocument(input: { fullname: string, username: string, email: string, requesterIp: string, companyCode: number, fileType: FileType }): Observable<{ message: string }> {
    const date = new Date();
    const payload = {
      fullname: input.fullname,
      username: input.username,
      email: input.email,
      requesterIp: input.requesterIp,
      companyId: input.companyCode,
      fileType: input.fileType,
      date: date.toISOString()
    };

    return this.http.post<{ message: string }>(`${this.publisherUrl}/publisher/process-file`, payload);
  }

  sendModel(input: { fullname: string, username: string, email: string, requesterIp: string, companyCode: number }): Observable<{ message: string }> {
    const date = new Date();
    const payload = {
      fullname: input.fullname,
      username: input.username,
      email: input.email,
      requesterIp: input.requesterIp,
      companyId: input.companyCode,
      fileType: 'XLSX',
      date: date.toISOString()
    };

    return this.http.post<{ message: string }>(`${this.publisherUrl}/publisher/send-model`, payload);
  }

  sendTechnicianReport(input: { 
    email: string, 
    fullname: string, 
    company: string, 
    technicianId: string, 
    startDate: string, 
    endDate: string, 
    validations: string, 
    regionals: string, 
    directors: string, 
    families: string 
  }) {
    return this.http.post<{ message: string }>(`${this.publisherUrl}/publisher/report/technicians`, input);
  }
}
