import { AgmMap, ControlPosition, LatLngBounds } from '@agm/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { ITicket, VandalismAnalytical } from 'src/app/interfaces';
import { FilterService, Namespace } from 'src/app/services/filter.service';
import { AnalyticalService } from 'src/app/services/analytical.service';
import { TicketPopUpComponent } from 'src/app/maps/ticket-pop-up/ticket-pop-up.component';
import { LegendItem } from 'src/app/interfaces/models/ILegendItem';
import { analyticalDefaultLegend, getDateUtils, getDefaultFilters } from 'src/app/utils/interfaces/constants';
import { AnalyticalLegend } from 'src/app/interfaces/enums/AnalyticalLegend';
import { CauseOption } from 'src/app/interfaces/enums/CauseOption';
import { icons } from 'src/app/utils/icons';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

const { currentMonth, currentYear } = getDateUtils();

export const defaultActiveFilters = getDefaultFilters({
  month: currentMonth,
  year: currentYear,
  causeGroup: `${CauseOption.VANDALISMO}`
});

export interface TicketLocation {
  guid: string,
  ticketId: number,
  lat: number,
  lng: number,
  date: string,
  operation: string,
  icon: string,
  latStart: number,
  lngStart: number,
  latEnd: number,
  lngEnd: number
}

@Component({
  selector: 'app-analytical-fullscreen-map',
  templateUrl: './analytical-fullscreen-map.component.html',
  styleUrls: [],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        'max-height': '500px',
        opacity: '1',
        visibility: 'visible'
      })),
      state('out', style({
        'max-height': '0',
        opacity: '0',
        visibility: 'hidden'
      })),
      transition('in => out', [animate('800ms ease-in-out')]),
      transition('out => in', [animate('800ms ease-in-out')])
    ])
  ]
})
export class AnalyticalFullscreenMapComponent implements OnInit {

  private static ANALITICO_URL = '/vandalismo/analitico';

  @ViewChild(AgmMap) agmMap: AgmMap;
  map: google.maps.Map;

  NAMESPACE: Namespace = 'analyticalFullscreen';
  icons=icons
  data: BehaviorSubject<VandalismAnalytical.Output> = new BehaviorSubject(new VandalismAnalytical.Output());
  activeFilters: BehaviorSubject<VandalismAnalytical.InputParams> = new BehaviorSubject(defaultActiveFilters);
  loading: boolean = true;
  error: boolean = false;
  undoDisabled: BehaviorSubject<boolean> = new BehaviorSubject(true);
  tickets: BehaviorSubject<TicketLocation[]> = new BehaviorSubject([]);
  peads: BehaviorSubject<TicketLocation[]> = new BehaviorSubject([]);
  filteredMarkers: BehaviorSubject<TicketLocation[]> = new BehaviorSubject([]);
  activeYears: string[] = [];
  historyActiveFilters: Array<VandalismAnalytical.InputParams> = [];
  searchControl = new FormControl();  
  searchInput = new Subject<string>();
  activitiesNumber: number = 0;
  legends: BehaviorSubject<LegendItem<AnalyticalLegend>[]> = new BehaviorSubject(analyticalDefaultLegend);
  isDivVisible: boolean = false;
  isAnalytic: boolean = true;
  isReset: boolean = false;
  isSearchVisible: boolean = false; 
  mapConfig = {
    zoom: 4,
    zoomControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    iconRedUrl: '/assets/maps/red-circle.svg',
    iconBlackUrl: '/assets/maps/black-circle.svg',
    iconGreenUrl: '/assets/maps/polygon.svg',
    center: {
      lat: -15.8517,
      lng: -48.5799
    }
  }

  constructor(
    private activeModal: NgbActiveModal,
    private filterService: FilterService,
    private analyticalService: AnalyticalService,
    private modalService: NgbModal,
    private http: HttpClient,
    private router: Router
  ) {

    this.filterService.setDefaultFilters<VandalismAnalytical.InputParams>({
      namespace: this.NAMESPACE,
      defaultFilters: defaultActiveFilters
    });

    this.filterService.getFiltersObservable<VandalismAnalytical.InputParams>({
      namespace: this.NAMESPACE
    })
      .pipe(debounceTime(1000))
      .subscribe((activeFilters) => {
        this.updateData(activeFilters)
      });      
  }

  ngOnInit(): void {
    this.data.subscribe((value) => {
      const ticketsWithLatLng = value.tickets;
      this.activitiesNumber = ticketsWithLatLng.length;
      this.filteredMarkers.next(value.tickets);
    });

    this.searchInput.pipe(
      debounceTime(2000),
      distinctUntilChanged()
    ).subscribe((value: string) => { 
      this.onSearch(value)
    });
  }

  updateData(activeFilters: VandalismAnalytical.InputParams) {
    this.activeFilters.next(activeFilters);
    this.activeYears = activeFilters.year.split(',');
    const filteredTickets = this.analyticalService.filterTickets(activeFilters);
    const { dropdownList, selectedList } = this.analyticalService.getFilters(activeFilters, filteredTickets);
    this.getLocations(dropdownList, selectedList, activeFilters);    
  }

  stringToBoolean(value: string | boolean) {
    if (value === "true") return true;
    if (value === "false") return false;
    if (value) return true;
    if (!value) return false;
    return value;
  } 
  
  getLocations(dropdownList, selectedList, activeFilters) {

    this.loading = true;
    this.error = false;
    const baseUrl = environment.baseUrl;
    const url = `${baseUrl}/vandalism/analytical/locations`;

    const isPead = this.stringToBoolean(activeFilters.isPead);
    const isAccumulated = this.stringToBoolean(activeFilters.isAccumulated); 
  
    const formatAsArray = (value: any): string[] => {
      return !value || value.length === 0 ? null : Array.isArray(value) ? value : value.split(',');
    };

    const body = {
      "families": formatAsArray(activeFilters.family),
      "nets": formatAsArray(activeFilters.net),
      "cities": formatAsArray(activeFilters.city),
      "subClusters": formatAsArray(activeFilters.subcluster),
      "clusters": formatAsArray(activeFilters.cluster),
      "states": formatAsArray(activeFilters.state),
      "directors": formatAsArray(activeFilters.group),
      "regionals": formatAsArray(activeFilters.regional),
      "years": formatAsArray(activeFilters.year),
      "months": formatAsArray(activeFilters.month),
      "days": formatAsArray(activeFilters.day),
      "causeGroup": formatAsArray(activeFilters.causeGroup),
      "isPead": isPead,
      "isAccumulated": isAccumulated
    };
  
    return this.http
      .post<{ peads: [], tickets: [] }>(url, body)
      .pipe(
        catchError(() => {
          this.loading = false;
          this.error = true;
          return of(null);
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((response) => {
        if (response) {
          console.log(response);
          this.data.next(new VandalismAnalytical.Output({
            dropdownList,
            selectedList,
            activeFilters,
            tickets: response.tickets ? response.tickets.filter(this.validateTicketLocation) : [],
            peads: response.peads ? response.peads.filter(this.validatePeadLocation) : []
          }));
        }
      });
  }

  updateMarkers(newBounds: LatLngBounds) {
    this.filteredMarkers.next(this.data.value.tickets.filter((marker) => {
      let markerLatLng = new google.maps.LatLng(
        parseFloat(`${marker.lat}`),
        parseFloat(`${marker.lng}`)
      );
      return newBounds.contains(markerLatLng);
    }));
  }

  isValidCoordinate = (coordinate: number): boolean => {
    return !isNaN(coordinate) && coordinate !== null;
  }

  validateTicketLocation = (ticketLocation: TicketLocation): boolean => {
    return (this.isValidCoordinate(ticketLocation.lat) && this.isValidCoordinate(ticketLocation.lng));
  }

  validatePeadLocation = (location: TicketLocation): boolean => {
    return this.isValidCoordinate(location.latStart) && this.isValidCoordinate(location.lngStart) && this.isValidCoordinate(location.latEnd) && this.isValidCoordinate(location.lngEnd)
  }

  openTicketPopUp(ticket: ITicket) {    
    const modalRef = this.modalService.open(TicketPopUpComponent, { 
      size: 'lg',
      windowClass: 'custom-modal-height' 
    });
    modalRef.componentInstance.modalData = ticket;    
  }  

  createCustomWindowed() {
    const svgImage = document.createElement("img");
    svgImage.src = "assets/maps/fullscreen_desativado.svg";
    svgImage.width = 22;
    svgImage.height = 22;
    const controlButton = document.createElement("button");
    controlButton.type = "button"
    controlButton.title = "Desativar o fullscreen do mapa";
    controlButton.setAttribute('class', 'control-button');
    controlButton.appendChild(svgImage);
    controlButton.addEventListener('click', () => this.closeModal());
    return controlButton;
  }
  
  onMapReady(map: google.maps.Map) {
    const customControlDiv = document.createElement('div');
    const customFullScreenControl = this.createCustomWindowed();
    customControlDiv.appendChild(customFullScreenControl);
    map.controls[ControlPosition.BOTTOM_RIGHT].push(customControlDiv);
    this.map = map;
  }

  treatTooltip(marker: TicketLocation) {
    const id = marker.ticketId;
    const date = marker.date;
    const operation = marker.operation;    
    return `ID: ${id}\nData: ${date}\nOperação: ${operation}`    
  }

  closeModal() {
    this.activeModal.close();
    this.router.navigate([AnalyticalFullscreenMapComponent.ANALITICO_URL])
  }

  openHeaderFilter() {
    this.isDivVisible = !this.isDivVisible;    
  }

  iconUrl(icon: string) {
    return `/assets/maps/${icon}.svg`;
  }

  resetFilters() {
    const center = new google.maps.LatLng(this.mapConfig.center.lat, this.mapConfig.center.lat);
    this.map.setCenter(center);
    this.map.setZoom(this.mapConfig.zoom);
    this.filterService.updateMultipleFilters({
      namespace: this.NAMESPACE,
      newFilters: defaultActiveFilters,
    });
  }
  
  toggleSearchButton() {
    this.isSearchVisible = !this.isSearchVisible;
  }

  onSearch(value: string): void {
    this.loading = true;

    const conditionForSearch = value !== '' && value.length > 5
    if (conditionForSearch) {
      const response = this.analyticalService.getTicketsById(value);
      response.subscribe((data: any) => {
        try {
          this.loading = false;
          const toMap = (ticket: ITicket): TicketLocation => {
            return {
              guid: ticket.guid,
              ticketId: ticket.ticketId,
              lat: ticket.lat,
              lng: ticket.lng,
              date: ticket.reportedDate,
              operation: ticket.operational,
              icon: ticket.causeGroup === 'PEAD' ? 'polygon' : (ticket.productOperational1 === 'RESIDENCIAL' ? 'red-circle' : 'black-circle'),
              latStart: ticket.latStart,
              lngStart: ticket.lngStart,
              latEnd: ticket.latEnd,
              lngEnd: ticket.lngEnd
            }
          }
          const mappedTicket: TicketLocation[] = data.map(toMap);
          const { dropdownList, selectedList, activeFilters } = this.data.value;
          this.data.next(new VandalismAnalytical.Output({
            dropdownList,
            selectedList,
            activeFilters,
            tickets: mappedTicket,
            peads: []
          }))
        } catch (e) {
          this.loading = false;
          return;
        }
      });
    } else {
      this.filterService.actionChange({ namespace: this.NAMESPACE });
    }
  }

  onUndo(): void {
    const prevValue = this.historyActiveFilters.pop();
    if (prevValue !== undefined) {
      this.filterService.updateMultipleFilters<VandalismAnalytical.InputParams>({
        namespace: this.NAMESPACE,
        newFilters: prevValue
      });
      const isLastElement: boolean = this.historyActiveFilters.length === 0;
      this.undoDisabled.next(isLastElement);
    }
  }

  onReset() {
    this.filterService.updateMultipleFilters({
      namespace: this.NAMESPACE,
      newFilters: defaultActiveFilters,
    });
  }

  onVandalism() {
    this.onChange(`causeGroup:VANDALISMO`);
    this.onActivatePeadsChange(false);
    this.onAccumulatedPeadsChange(false);
  }

  onActivatePeadsChange(isActivated: boolean) {
    this.toggleActivatePeads(isActivated);
  }

  onAccumulatedPeadsChange(isActivated: boolean) {
    this.toggleAccumulatedPeads(isActivated);
  }

  toggleActivatePeads(shouldActivate: boolean) {
    const isActive = shouldActivate !== undefined ? shouldActivate : !this.activeFilters.value.isPead;
    const isPead = (legend: LegendItem<AnalyticalLegend>) => legend.name === CauseOption.PEAD.toString();
    const legendPead = analyticalDefaultLegend.find(isPead);
    const updatedLegendPead: LegendItem<AnalyticalLegend> = { ...legendPead, isVisible: isActive };
    const updatedLegends = this.legends.value.map(legend => 
      legend.name === CauseOption.PEAD.toString() ? updatedLegendPead : legend
    );
    this.legends.next(updatedLegends);
    this.onChange(`isPead:${isActive}`);
  }

  toggleAccumulatedPeads(shouldActivate: boolean) {
    const isActive = shouldActivate !== undefined ? shouldActivate : !this.activeFilters.value.isAccumulated;
    this.onChange(`isAccumulated:${isActive}`);
  }

  onChange(event: string) {
    const [key, values] = event.split(':');

    let newActiveFilters = this.activeFilters.value;
    let newValues = values;
    const isLastElement: boolean = this.historyActiveFilters.length === 0;
    this.historyActiveFilters.push(newActiveFilters);
    this.undoDisabled.next(isLastElement);

    this.filterService.updateFilters<VandalismAnalytical.InputParams>({
      namespace: this.NAMESPACE,
      key,
      values: newValues
    });
  }

  ngOnDestroy() {
    this.data.unsubscribe();
    this.filteredMarkers.unsubscribe();
    this.undoDisabled.unsubscribe();
    this.searchInput.unsubscribe();
    this.legends.unsubscribe();
  }
}
